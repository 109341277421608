import { ReactNode, ReactElement } from 'react';
import { UISpinner } from '../../animations';
import { UIColors } from '../../styles/ui-colors';
import { UIIcon, UIIconName } from '../../ui-icon';
import styles from './ui-button.module.scss';
import { UIIntent, UIIntentDefault } from '../../intent';
import { t4FontSize, t5FontSize, t6FontSize, UIText } from '../../typography';

type UIButtonProps = {
	large?: boolean;
	small?: boolean;
	bold?: boolean;
	intent?: UIIntent;
	disabled?: boolean;
	outlined?: boolean;
	textOnly?: boolean;
	loading?: boolean;
	onClick?: () => void;
	children?: ReactNode;
	leftIcon?: UIIconName;
	type?: 'submit' | 'reset' | 'button' | undefined;
};
export function UIButton(props: UIButtonProps): ReactElement {
	const {
		large = false,
		small = false,
		bold = true,
		intent = UIIntentDefault,
		disabled = false,
		outlined = false,
		textOnly = false,
		loading = false,
		onClick,
		children,
		leftIcon,
		type
	} = props;

	const classNames: string[] = [styles.button];

	// Color styles
	let textColor: string = UIColors.primary;
	if (intent === 'primary') {
		if (outlined) {
			classNames.push(styles.primaryOutlined);
			textColor = UIColors.primary;
		} else if (textOnly) {
			classNames.push(styles.primaryTextOnly);
			textColor = UIColors.primary;
		} else {
			classNames.push(styles.primaryFilled);
			textColor = 'white';
		}
	} else if (intent === 'success') {
		if (outlined) {
			classNames.push(styles.successOutlined);
			textColor = UIColors.success;
		} else if (textOnly) {
			classNames.push(styles.successTextOnly);
			textColor = UIColors.success;
		} else {
			classNames.push(styles.successFilled);
			textColor = 'white';
		}
	} else if (intent === 'danger') {
		if (outlined) {
			classNames.push(styles.dangerOutlined);
			textColor = UIColors.danger;
		} else if (textOnly) {
			classNames.push(styles.dangerTextOnly);
			textColor = UIColors.danger;
		} else {
			classNames.push(styles.dangerFilled);
			textColor = 'white';
		}
	}

	// Padding style
	if (small) {
		classNames.push(styles.paddingSmall);
	} else if (large) {
		classNames.push(styles.paddingLarge);
	} else {
		classNames.push(styles.paddingNormal);
	}

	// Icon size
	let iconSize: number;
	if (small) {
		iconSize = t6FontSize;
	} else if (large) {
		iconSize = t4FontSize;
	} else {
		iconSize = t5FontSize;
	}

	// Disabled style
	if (disabled) {
		classNames.push(styles.disabled);
	}

	return (
		<button
			className={classNames.join(' ')}
			type={type}
			disabled={disabled}
			onClick={onClick}
		>
			{loading && (
				<div className={styles.loadingContainer}>
					<UISpinner />
				</div>
			)}
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flex: 1,
					opacity: loading ? 0 : 1
				}}
			>
				{leftIcon && (
					<div style={{ marginRight: 5 }}>
						<UIIcon name={leftIcon} size={iconSize} color={textColor} />
					</div>
				)}
				<UIText large={large} small={small} bold={bold} color={textColor}>
					{children}
				</UIText>
			</div>
		</button>
	);
}
