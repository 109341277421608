import { ReactElement } from 'react';
import styles from './ui-spinner.module.scss';

const SIZE = 16;

type UISpinnerProps = {
	size?: number;
};

export function UISpinner(props: UISpinnerProps): ReactElement {
	const { size = SIZE } = props;
	return (
		<div className={styles.loader} style={{ width: size, height: size }} />
	);
}
