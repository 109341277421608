import tinycolor from 'tinycolor2';

export function opacity(value: number): string {
	const norm = Math.max(0, Math.min(1, value));
	const to8bit = Math.ceil(norm * 255);
	const hex = to8bit.toString(16);

	if (hex.length === 1) {
		return `0${hex}`;
	} else {
		return hex;
	}
}

const MUTED_SHADE = 30;

const eerieBlack = '#141414';
const coquelicot = '#FC8970'; // '#E32C03';
const greenYrb = '#548014'; // '#73BA26';
const davysGrey = '#575757';
const platinum = '#F6F3EE';

export const UIColors = {
	primary: eerieBlack,
	success: greenYrb,
	danger: coquelicot,
	backgroundLight: platinum,
	backgroundDark: eerieBlack,
	muted: davysGrey,
	text: {
		normal: eerieBlack,
		muted: davysGrey,
		primary: eerieBlack,
		danger: coquelicot,
		success: greenYrb
	},
	input: {
		unfocused: {
			border: davysGrey,
			caret: undefined
		},
		focused: {
			border: eerieBlack,
			caret: eerieBlack
		},
		error: {
			border: coquelicot,
			caret: eerieBlack
		},
		success: {
			border: greenYrb,
			caret: greenYrb
		}
	},
	map: {
		markers: {
			start: greenYrb,
			finish: eerieBlack
		}
	},
	muteColor: (color: string, shade: number = MUTED_SHADE): string => {
		return tinycolor(color).lighten(shade).toString();
	},
	lightenColor: (color: string, shade: number = MUTED_SHADE): string => {
		return tinycolor(color).lighten(shade).toString();
	},
	darkenColor: (color: string, shade: number = MUTED_SHADE): string => {
		return tinycolor(color).darken(shade).toString();
	}
};
