export const t1FontSize = 42;
export const t2FontSize = 33;
export const t3FontSize = 26;
export const t4FontSize = 20;
export const t5FontSize = 16;
export const t6FontSize = 13;

export const t1LineHeight = 68;
export const t2LineHeight = 54;
export const t3LineHeight = 42;
export const t4LineHeight = 33;
export const t5LineHeight = 26;
export const t6LineHeight = 20;
