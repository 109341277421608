import React from 'react';
import * as icons from 'react-feather';

export type UIIconName = keyof typeof icons;

export type UIIconProps = {
	name: UIIconName;
	size?: number;
	color?: string;
};

export function UIIcon({ name, ...rest }: UIIconProps) {
	// @ts-ignore
	const IconComponent = icons[name];
	return <IconComponent {...rest} />;
}
