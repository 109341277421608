import { ReactElement, ReactNode } from 'react';
import styles from './ui-text.module.scss';
import { UIColors } from '../../styles/ui-colors';

type UITextProps = {
	children?: ReactNode;
	large?: boolean;
	small?: boolean;
	fontSize?: number;
	medium?: boolean;
	bold?: boolean;
	italic?: boolean;
	muted?: boolean;
	fontFamily?: 'Lora' | 'Roboto';
	color?: string;
	running?: boolean;
	underline?: boolean;
	textAlign?:
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'match-parent';
};

export function UIText(props: UITextProps): ReactElement {
	const {
		children,
		large,
		small,
		medium = false,
		bold = false,
		fontSize,
		italic,
		muted,
		fontFamily = 'Roboto',
		color = UIColors.primary,
		running,
		underline,
		textAlign
	} = props;

	const classNames: string[] = [];

	// Font size
	if (small) {
		classNames.push(styles.fontSizeSmall);
	} else if (large) {
		classNames.push(styles.fontSizeLarge);
	} else {
		classNames.push(styles.fontSizeNormal);
	}

	// Line height
	if (running) {
		if (small) {
			classNames.push(styles.lineHeightSmall);
		} else if (large) {
			classNames.push(styles.lineHeightNormal);
		} else {
			classNames.push(styles.lineHeightLarge);
		}
	}

	// Font weight
	if (medium) {
		classNames.push(styles.fontWeightMedium);
	} else if (bold) {
		classNames.push(styles.fontWeightBold);
	} else {
		classNames.push(styles.fontWeightNormal);
	}

	// Font style
	if (italic) {
		classNames.push(styles.fontStyleItalic);
	} else {
		classNames.push(styles.fontStyleNormal);
	}

	// Text decoration
	if (underline) {
		classNames.push(styles.textDecorationUnderline);
	} else {
		classNames.push(styles.textDecorationNone);
	}

	if (fontFamily === 'Lora') {
		classNames.push(styles.fontFamilyLora);
	} else if (fontFamily === 'Roboto') {
		classNames.push(styles.fontFamilyRoboto);
	}

	return (
		<span
			className={classNames.join(' ')}
			style={{
				color,
				textAlign,
				opacity: muted ? 0.5 : 1,
				fontSize
			}}
		>
			{children}
		</span>
	);
}
