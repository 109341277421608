import { ReactElement, useEffect } from 'react';
import styles from './home.module.scss';

export function HomePage(): ReactElement {
	useEffect(() => {
		// @ts-ignore
		window?.ReactNativeWebView?.postMessage('app-close-webview');
	}, []);
	return <div className={styles.container}></div>;
}
