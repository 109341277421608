import { ReactElement, useEffect } from 'react';
import { UIButton } from '../../ui/buttons';
import { UIText } from '../../ui/typography';
import styles from './success.module.scss';
import { Player } from '@lottiefiles/react-lottie-player';

export function SubscriptionSuccessPage(): ReactElement {
	useEffect(() => {
		// @ts-ignore
		window?.ReactNativeWebView?.postMessage('app-subscribe-success');
	}, []);

	return (
		<div className={styles.page}>
			<div className={styles.innerPage}>
				<div className={styles.contentContainer}>
					<Player
						autoplay
						loop={false}
						keepLastFrame
						src={require('../../assets/lottie/success-confetti.json')}
						style={{ height: '250px', width: '250px' }}
					/>
					<UIText fontFamily="Lora" textAlign="center" fontSize={32}>
						Subscription successfull!
					</UIText>
					<div
						style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}
					>
						<UIButton
							onClick={() => {
								// @ts-ignore
								window?.ReactNativeWebView?.postMessage('app-close-webview');
							}}
						>
							Continue
						</UIButton>
					</div>
				</div>
				<div
					style={{
						position: 'absolute',
						left: 0,
						top: 0,
						right: 0,
						bottom: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						pointerEvents: 'none'
					}}
				>
					<Player
						autoplay
						loop={false}
						src={require('../../assets/lottie/confetti.json')}
						style={{ width: 800, height: 800 }}
					/>
				</div>
			</div>
		</div>
	);
}
