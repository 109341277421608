import { ReactElement } from 'react';
import './app.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NotFoundPage } from './pages/not-found';
import { SubscriptionSuccessPage } from './pages/success';
import { HomePage } from './pages/home';

function App(): ReactElement {
	return (
		<Routes>
			<Route path="/" element={<HomePage />} />
			<Route path="/success" element={<SubscriptionSuccessPage />} />
			<Route path="/not-found" element={<NotFoundPage />} />
			<Route path="*" element={<Navigate to="/not-found" />} />
		</Routes>
	);
}

export default App;
